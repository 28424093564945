function cropNewsText() {
  const newsTitles = document.getElementsByClassName("news-item__title");
  const newsTexts = document.getElementsByClassName("news-item__text");

  for (let i = 0; i < newsTitles.length; i++) {
    if (newsTitles[i].innerText.length > 90) {
      newsTitles[i].innerText = newsTitles[i].innerText.slice(0, 90) + "...";
    }
    if (newsTexts[i].innerText.length > 90) {
      newsTexts[i].innerText = newsTexts[i].innerText.slice(0, 90) + "...";
    }
  }
}

cropNewsText();

function mainBlockSlider() {
  let position = 0;
  const slides = document.getElementsByClassName("main__item-container");
  const dots = document.getElementsByClassName("main__slider-dots__dot");
  const arrowLeft = document.getElementById("main__slider-arrow-left");
  const arrowRight = document.getElementById("main__slider-arrow-right");

  function updateSlider() {
    for (let i = 0; i < dots.length; i++) {
      dots[i].classList.remove("active");
      dots[i].classList.remove("center");
      dots[i].classList.remove("last");
      dots[i].classList.remove("next");
      slides[i].classList.remove("active");
    }

    dots[Math.abs(position)].classList.add("active");
    dots[Math.abs(position)].classList.add("next");
    if (Math.abs(position) === dots.length - 1) {
      dots[Math.abs(position)].classList.add("last");
    }
    if (Math.abs(position) === dots.length - 2) {
      dots[Math.abs(position)].classList.add("center");
    }

    slides[position].classList.add("active");
  }

  function moveSlides(direction) {
    if (direction === "left") {
      if (position === 0) {
        position = slides.length - 1;
      } else {
        position -= 1;
      }
    } else if (direction === "right") {
      if (position === slides.length - 1) {
        position = 0;
      } else {
        position += 1;
      }
    }
    updateSlider();
  }

  arrowLeft.addEventListener("click", () => {
    moveSlides("left");
  });
  arrowRight.addEventListener("click", () => {
    moveSlides("right");
  });

  setInterval(() => {
    moveSlides("right");
  }, 15000);
}

mainBlockSlider();

function newsBlockSlider() {
  const leftArrow = document.getElementById("news__btns-arrow-left");
  const rightArrow = document.getElementById("news__btns-arrow-right");
  const newsContainer = document.getElementById("news__news-container");
  let isMouseDown = false;
  let startPosX;
  let startScrollLeft;

  leftArrow.addEventListener("click", () => {
    newsContainer.scroll({
      behavior: "smooth",
      left: newsContainer.scrollLeft - 300,
      top: 0,
    });
  });

  rightArrow.addEventListener("click", () => {
    newsContainer.scroll({
      behavior: "smooth",
      left: newsContainer.scrollLeft + 300,
      top: 0,
    });
  });

  newsContainer.addEventListener("mousedown", (event) => {
    if (event.button === 0) {
      // Проверяем, что зажата левая кнопка мыши
      newsContainer.setAttribute("style", "cursor: grabbing");
      isMouseDown = true;
      startPosX = event.clientX;
      startScrollLeft = newsContainer.scrollLeft;
    }
  });

  newsContainer.addEventListener("mousemove", (event) => {
    if (isMouseDown) {
      const deltaX = event.clientX - startPosX;
      newsContainer.scrollLeft = startScrollLeft - deltaX;
    }
  });

  newsContainer.addEventListener("mouseup", () => {
    newsContainer.setAttribute("style", "cursor: grab");
    isMouseDown = false;
  });
}

newsBlockSlider();

const sliderBlockInfo = [
  {
    title: "Коммутаторы ядра",
    text: "Коммутаторы ядра - это мощные, надежные, высокопроизводительные устройства являющиеся центральным звеном корпоративных сетей. Они объединяют другие сегменты сети уровня агрегации и доступа, а так же обеспечивают маршрутизацию и коммутацию трафика между клиентами, серверами, Интернет провайдерами и другой сетевой инфраструктурой.",
    img: "./img/components-slider-pic.jpg",
  },
  {
    title: "Коммутаторы агрегации",
    text: "Коммутаторы агрегации (или коммутаторы распределения) – это устройства, которые принимают, обрабатывают и распределяют траффик уровня доступа. В зависимости от конфигурации сети, коммутаторы агрегации перенаправляют трафик на уровень ядра или маршрутизируют пакеты локально, без пересылки на коммутаторы уровня ядра.",
    img: "./img/components-slider-pic-2.jpg",
  },
  {
    title: "Коммутаторы доступа",
    text: "Коммутаторы доступа – это устройства, которые обеспечивают подключение к локальной сети конечные устройства, такие как – компьютеры, беспроводные точки доступа, IP- видеокамеры, IP- телефоны, сетевые принтеры и другие устройства.",
    img: "./img/components-slider-pic-3.jpg",
  },
];

function sliderBlockSlider() {
  const img = document.getElementById("slider__picture-img");
  const title = document.getElementById("slider__info-title");
  const text = document.getElementById("slider__info-text");
  const titles = document.getElementsByClassName("slider__info-header-item");

  function clearTitles() {
    for (let i = 0; i < titles.length; i++) {
      titles[i].classList.remove("active");
    }
  }

  for (let i = 0; i < titles.length; i++) {
    titles[i].addEventListener("click", () => {
      clearTitles();
      titles[i].classList.add("active");
      img.setAttribute("src", sliderBlockInfo[i].img);
      title.innerText = sliderBlockInfo[i].title;
      text.innerText = sliderBlockInfo[i].text;
    });
  }
}

sliderBlockSlider();

function mapBlockScale() {
  const mapContainer = document.getElementsByClassName("map__map-container")[0];
  const mapContainerAdditional = document.getElementsByClassName(
    "map__map-container-additional"
  )[0];
  const plusBtn = document.getElementById("map-container__scale-btns-plus");
  const minusBtn = document.getElementById("map-container__scale-btns-minus");
  let zoom = 0.7;

  mapContainerAdditional.scrollTop = 300;
  mapContainerAdditional.scrollLeft = 500;

  plusBtn.addEventListener("click", () => {
    if (zoom < 2.1) {
      zoom += 0.2;
      mapContainer.setAttribute("style", `transform: scale(${zoom})`);
    }
  });

  minusBtn.addEventListener("click", () => {
    if (zoom > 0.2) {
      zoom -= 0.2;
      mapContainer.setAttribute("style", `transform: scale(${zoom})`);
    }
  });
}

mapBlockScale();

function mapShowCardsOnClickEvents() {
  const mapItems = document.getElementsByClassName("map-container__item");

  for (let i = 0; i < mapItems.length; i++) {
    mapItems[i].addEventListener("click", () => {
      mapItems[i].classList.toggle("active");
      mapItems[i].children[0].children[0].src = './img/map-item-icon-active.svg';
    });
    mapItems[i].addEventListener("mouseleave", () => {
      mapItems[i].classList.remove("active");
      mapItems[i].children[0].children[0].src = './img/map-item-icon.svg';
    });
  }
}

mapShowCardsOnClickEvents();
